import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

type ISpy = { id: string; data: any };

type ImageUrlData = { imageId: string; url: string };

type ISpyImage = {
  imageID: string;
  imageSecurity: string;
  imageDate: string;
  imageExtents: any;
  imagePath: string;
  imageGuide: string;
};

type AtrDetectViewImageType = { url: string; feature: any; lat: any; lon: any };

const ispyAdapter = createEntityAdapter<ISpy>({
  selectId: cr => cr.id,
  sortComparer: (a, b) => a.data.localeCompare(b.data),
});

interface ExtendedEntityAdapterState {
  selectedDetects: any;
  showAll: boolean;
  iSpyOpen: boolean;
  instanceId: null | string;
  iSpyVersion: null | string;
  image: null | ISpyImage;
  viewportExtents: null | any;
  syncViewport: boolean;
  lastEventTimestamp: null | Date;
  lastError: null | any;
  winRef: null | any;
  iSpyViewportCenter: null | object;
}

const initialState: ExtendedEntityAdapterState = {
  selectedDetects: [],
  showAll: true,
  iSpyOpen: false,
  instanceId: null,
  iSpyVersion: null,
  image: null,
  viewportExtents: null,
  syncViewport: true,
  lastEventTimestamp: null,
  lastError: null,
  winRef: null,
  iSpyViewportCenter: null,
};

let iSpyInterface = null;

export const iSpySlice = createSlice({
  name: 'iSpy',
  initialState: ispyAdapter.getInitialState(initialState),
  reducers: {
    launch(state, action) {
      state.winRef = iSpyOpenAction(state, action.payload);
      state.iSpyOpen = true;
    },
    viewImage(state, action) {
      const { imageId, url } = buildViewImageUrl(action.payload);
      console.log('TODO: view image url ', url);
      state.winRef = iSpyOpenAction(state, url);
      state.iSpyOpen = true;
      testISpyRemoteAction(action.payload);
    },
    viewDetects(state, action) {
      console.log('TODO: view detects');
      const { imageId, url } = buildViewImageUrl(action.payload);
      state.winRef = iSpyOpenAction(state, url);
      state.iSpyOpen = true;
      drawDetect('state.instanceId', action.payload);
      // testISpyRemoteAction(action.payload);
    },
    onEvent(state, action) {
      console.log('TODO: iSpy event event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
    onStatus(state, action) {
      console.log('iSpy status event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      state.instanceId = action.payload.instanceID;
      state.iSpyVersion = action.payload.iSpyVersion;
      state.image = updateImage(action.payload);
      state.viewportExtents = action.payload.viewportExtents;
    },
    onConnection(state, action) {
      console.log('iSpy connection event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      iSpyInterface = action.payload.iSpyInterface;
      state.instanceId = action.payload.instanceID;
      state.iSpyVersion = action.payload.iSpyVersion;
    },
    onImageLoad(state, action) {
      console.log('iSpy image load event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      state.image = updateImage(action.payload);
    },
    onImageClicked(state, action) {
      console.log('iSpy image click event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      //TODO: any on image click?
    },
    onViewportChanged(state, action) {
      console.log('iSpy viewport change event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      state.viewportExtents = action.payload.viewportExtents;
      if (state.syncViewport) {
        console.log('sync viewport');
        try {
          state.iSpyViewportCenter = syncViewport(action.payload.viewportExtents);
        } catch (err) {
          console.log(`sync viewport err ${err}`);
        }
      }
    },
    onShapeCreated(state, action) {
      console.log('iSpy onShapeCreated event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
    onShapeUpdated(state, action) {
      console.log('iSpy onShapeUpdated event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
    onShapeDeleted(state, action) {
      console.log('iSpy onShapeDeleted event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
    onShapeSelected(state, action) {
      console.log('iSpy onShapeSelected event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
    onDisconnection(state, action) {
      console.log('iSpy onDisconnection event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      state.instanceId = null;
      state.iSpyVersion = null;
      iSpyInterface = null;
      state.winRef = null;
    },
    onSystemDisconnect(state, action) {
      console.log('iSpy onSystemDisconnect event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      iSpyInterface = null;
      state.winRef = null;
    },
    onError(state, action) {
      console.log('iSpy onError event ', JSON.stringify(action));
      state.lastError = action.payload;
    },
  },
});

const updateImage = imagePayload => {
  const image: ISpyImage = {
    imageID: imagePayload.imageID,
    imageDate: imagePayload.imageDate,
    imagePath: imagePayload.imagePath,
    imageSecurity: imagePayload.imageSecurity,
    imageExtents: imagePayload.imageExtents,
    imageGuide: imagePayload.imageGuide,
  };
  return image;
};

const buildViewImageUrl = (payload: AtrDetectViewImageType) => {
  const imageId = payload.feature.properties['Image Location'];
  //lat=12.234&lon=98.765
  // const url = `${payload.url}/image?source_select=true&imageid=${image}`;
  let url = `${payload.url}?remote=true&source_select=true&imageid=${imageId}`;
  if (payload.lat && payload.lon) {
    url = `${url}&lat=${payload.lat}&lon=${payload.lon}`;
  } else {
    url = `${url}&fit=true`;
  }
  const data: ImageUrlData = { imageId, url };
  return data;
};

const drawDetect = (instanceID, payload: AtrDetectViewImageType) => {
  const feature = payload.feature;
  console.log(`draw detect ${JSON.stringify(feature)}`);
  const requestPayload = buildShapeRequestPayload(instanceID, feature.id, feature);
  console.log(`draw detect request  ${JSON.stringify(requestPayload)}`);
  // @ts-ignore
  iSpyInterface.drawShape(requestPayload);
};

const iSpyOpenAction = (state, url) => {
  let wRef;
  if (state.winRef) {
    wRef = state.winRef;
    state.winRef.focus();
  }
  if (url) {
    //wRef = window.open(url, '_blank', 'noopener, noreferrer');
    wRef = window.open(url, 'sgs-ispy', 'noreferrer');
  }
  return wRef;
};

const buildShapeRequestPayload = (instanceID, shapeID, feature, color = 'yellow') => {
  const vertices = feature.geometry.coordinates;
  const shapePayload = {
    instanceID: instanceID,
    shapes: [
      {
        type: 'Feature',
        id: shapeID,
        properties: {
          color: color,
          metadata: {
            title: shapeID,
            date: Date.now(),
            description: '',
            customMetadata: { a: 1, b: 'test', c: { x: 123, y: false } },
          },
          allowEditing: false,
        },
        geometry: {
          type: 'Polygon',
          coordinates: vertices,
        },
      },
    ],
    select: true,
  };
  return shapePayload;
};

const getMiddle = (prop, markers) => {
  let values = markers.map(m => m[prop]);
  let min = Math.min(...values);
  let max = Math.max(...values);
  if (prop === 'lon' && max - min > 180) {
    values = values.map(val => (val < max - 180 ? val + 360 : val));
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === 'lon' && result > 180) {
    result -= 360;
  }
  return result;
};

const findCenter = markers => {
  return {
    lat: getMiddle('lat', markers),
    lon: getMiddle('lon', markers),
  };
};

// Sync the sgs viewport with the iSpy viewport
// 20250124 -- not operational yet...experimental code to track connection events and debug
const syncViewport = viewportExtents => {
  if (viewportExtents) {
    const ul = viewportExtents.upperLeft;
    const ulm = { lat: ul.lat, lon: ul.lon };
    const ur = viewportExtents.upperRight;
    const urm = { lat: ur.lat, lon: ur.lon };
    const bl = viewportExtents.bottomLeft;
    const blm = { lat: bl.lat, lon: bl.lon };
    const br = viewportExtents.bottomRight;
    const brm = { lat: br.lat, lon: br.lon };
    const boundingBox = [ulm, urm, blm, brm];
    const centerOfBoundingBox = findCenter(boundingBox);
    console.log(`centerOfBoundingBox ${centerOfBoundingBox}`);
    return centerOfBoundingBox;
  }
  return null;
};

const testISpyRemoteAction = payload => {
  try {
    if (payload.lat && payload.lon) {
      // @ts-ignore
      iSpyInterface.centerViewport(payload.lat, payload.lon, 1.0);
    } else {
      // @ts-ignore
      iSpyInterface.centerViewport(39, -105, 1.0);
    }
  } catch (err) {
    console.log(`Error sending iSpy command ${err}`);
  }
};

const iSpyViewportCenterState = state => state.iSpy.iSpyViewportCenter;
export const selectiSpyViewportCenter = createSelector([iSpyViewportCenterState], x => x);

const { launch, viewImage, viewDetects } = iSpySlice.actions;
